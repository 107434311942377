.social-icons {
  justify-content: space-around;
}

.social-icons a {
  margin: 0 5px 10px;
}

@media (min-width: 1600px) {
  html {
    font-size: 1rem;
  }
}

.dhsv_lightning_checkout {
  z-index: 11;
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 80px);
  padding: 30px 0;
  background-color: rgba(72,72,72,.5);
}

@media (max-width: 1199.98px) {
  .dhsv_lightning_checkout {
    top: 70px;
    height: calc(100vh - 70px);
  }
}

@media (max-width: 991.98px) {
  .dhsv_lightning_checkout {
    top: 50px;
    height: calc(100vh - 50px);
  }
}

@media (max-width: 575.98px) {
  .dhsv_lightning_checkout {
    padding: 0;
  }

  .dhsv_lightning_checkout>.container {
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .single-property .dhsv_lightning_checkout {
    top: 0;
    z-index: 3605;
  }
}

